@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased text-gray-900 dark:text-white;
  }
}

@layer components {
  .container-custom {
    @apply container mx-auto px-4 sm:px-6 lg:px-8;
  }
}

@layer utilities {
  .animation-delay-200 {
    animation-delay: 200ms;
  }

  .animation-delay-300 {
    animation-delay: 300ms;
  }

  .animation-delay-400 {
    animation-delay: 400ms;
  }

  .animate-fade-in {
    animation: fade-in 0.8s ease-out forwards;
  }

  .animate-slide-up {
    animation: slide-up 0.8s ease-out forwards;
  }

  .animate-spin-slow {
    animation: spin 8s linear infinite;
  }

  .bg-grid-pattern {
    background-image: linear-gradient(to right, rgba(128, 128, 128, 0.1) 1px, transparent 1px),
                      linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 1px, transparent 1px);
    background-size: 24px 24px;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
